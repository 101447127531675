import React from "react"
import GoogleMapReact from 'google-map-react';
import jjLogoBlack from "../images/logos/jj-logo-black.png";

/**
 *
 */
class LocationMap extends React.Component {
    /**
     *
     */
    defaultMapProps;

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {isClient: false};
        this.setMapDefaults(props);
    }

    /**
     * Running this to re-hydrate the component
     * https://github.com/gatsbyjs/gatsby/issues/17914
     */
    componentDidMount() {
        this.setState({isClient: true});
    }

    /**
     *
     */
    setMapDefaults() {
        this.defaultMapProps = {
            center: {
                lat: +this.props.lat,
                lng: +this.props.lng
            },
            zoom: +this.props.zoom
        };
    }

    /**
     *
     * @returns {XML}
     */
    render() {
        const AnyReactComponent = ({text}) => <div><img src={jjLogoBlack} width="45" height="45" alt={text}/></div>;

        return (
            <div className="mb-3" style={{height: '250px', width: '100%'}} key={this.state.isClient}>
                <GoogleMapReact bootstrapURLKeys={{key: 'AIzaSyAXsBGCLBV_h75FIc1Mqp2CBY6eFSPR5I4'}}
                                defaultCenter={this.defaultMapProps.center}
                                defaultZoom={this.defaultMapProps.zoom}>
                    <AnyReactComponent lat={this.props.lat} lng={this.props.lng} text="JJ"/>
                </GoogleMapReact>
            </div>
        )
    }
}

export default LocationMap
