import React from "react"
import {format, parse, isValid, isBefore, isAfter, startOfTomorrow} from "date-fns"
import {utcToZonedTime} from "date-fns-tz"

/**
 *
 */
class OpenSign extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isClient: false,
            message: 'Loading...',
            badgeClass: 'badge-light'
        };
    }

    /**
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (this.props.openingHours && this.props.openingHours !== prevProps.openingHours) {
            this.resolveOpeningHoursData();
        }
    }

    /**
     *
     */
    resolveOpeningHoursData() {
        if (!this.props.openingHours) return;

        const thisStoreOpeningHours = this.props.openingHours.find(e => e.store_id === this.props.storeId);

        if (!thisStoreOpeningHours) return;

        let newMessage;
        let newBadgeClass;

        const timeZone = 'America/Los_Angeles';

        const now = new Date();
        const nowZoned = utcToZonedTime(now, timeZone);
        const nowDOW = format(nowZoned, 'EEEE').toLowerCase();
        const tomorrowZoned = utcToZonedTime(startOfTomorrow(), timeZone);
        const tomorrowDOW = format(tomorrowZoned, 'EEEE').toLowerCase();

        const todayOpenZoned = parse(
            format(nowZoned, 'yyyy-MM-dd') + ' ' + thisStoreOpeningHours[nowDOW + '_open'],
            'yyyy-MM-dd hh:mm:ss aaa',
            new Date()
        );
        const todayCloseZoned = parse(
            format(nowZoned, 'yyyy-MM-dd') + ' ' + thisStoreOpeningHours[nowDOW + '_close'],
            'yyyy-MM-dd hh:mm:ss aaa',
            new Date()
        );

        const tomorrowOpenZoned = parse(
            format(tomorrowZoned, 'yyyy-MM-dd') + ' ' + thisStoreOpeningHours[tomorrowDOW + '_open'],
            'yyyy-MM-dd hh:mm:ss aaa',
            new Date()
        );

        newMessage = 'Closed Today';
        newBadgeClass = 'badge-dark';

        if (isValid(todayOpenZoned)) {
            if (isAfter(nowZoned, todayOpenZoned)) {
                newMessage = 'Open Today';
                newBadgeClass = 'badge-success';

                if (isBefore(nowZoned, todayCloseZoned)) {
                    newMessage = 'Open Today Until ' + format(todayCloseZoned, 'h:mm aaa');
                    newBadgeClass = 'badge-success';
                } else {
                    if (isValid(tomorrowOpenZoned)) {
                        newMessage = 'Opens Tomorrow at ' + format(tomorrowOpenZoned, 'h:mm aaa');
                        newBadgeClass = 'badge-warning';
                    } else {
                        newMessage = 'Currently Closed';
                        newBadgeClass = 'badge-dark';
                    }
                }
            } else {
                newMessage = 'Opens Today at ' + format(todayOpenZoned, 'h:mm aaa');
                newBadgeClass = 'badge-warning';
            }
        }

        this.setState({
            isClient: true,
            message: newMessage,
            badgeClass: newBadgeClass
        });
    }

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <h5 className="my-3"><span
                className={"badge badge-pill px-3 py-2 " + this.state.badgeClass}>{this.state.message}<sup>&dagger;</sup></span>
            </h5>
        )
    }
}

export default OpenSign
