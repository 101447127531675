import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LocationMap from "../components/locationMap"
import OpenSign from "../components/openSign"

/**
 *
 */
class LocationsPage extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            isClient: false
        };
    }

    /**
     *
     */
    handleCallStoreClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-call-store"
            });
        }
    };

    /**
     *
     */
    handleTakeMeToMyLocalStoreClick = () => {
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: "click-button-take-me-to-my-local-store"
            });
        }
    };

    /**
     * Running this to re-hydrate the component
     * https://github.com/gatsbyjs/gatsby/issues/17914
     */
    componentDidMount() {
        this._asyncRequest = fetch('https://sheets.googleapis.com/v4/spreadsheets/166nSTsGLku9y_l0u73lXWs_QicQZ20xvp9N9VIabdsw/values:batchGet?ranges=Sheet1!A1:P5&majorDimension=ROWS&key=AIzaSyBpwiB4IDiiVaOM4pnf4lnsJZhWh35STnI')
            .then(response => response.json())
            .then(resultData => {
                    this._asyncRequest = null;
                    if (resultData.valueRanges) {
                        this.openingHoursRaw = resultData.valueRanges[0].values;
                        this.parseOpeningHours();
                        this.setState({isClient: true});
                    } else {
                        this.setState({isClient: true});
                    }
                }
            )
    }

    /**
     *
     */
    componentWillUnmount() {
        if (this._asyncRequest) {
            this._asyncRequest.cancel();
        }
    }

    /**
     *
     */
    parseOpeningHours() {
        const batchRowValues = this.openingHoursRaw;

        let rows = [];
        for (let i = 1; i < batchRowValues.length; i++) {
            let rowObject = {};
            for (let j = 0; j < batchRowValues[i].length; j++) {
                rowObject[batchRowValues[0][j]] = batchRowValues[i][j];
            }
            rows.push(rowObject);
        }

        this.openingHours = rows;
    }

    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="Locations"/>

                <div className="container mt-3">
                    <h1 className="text-center">Locations</h1>

                    <h2 className="text-primary">Jimmy John's &ndash; Concord</h2>
                    <OpenSign storeId="1" openingHours={this.openingHours}/>
                    <address>
                        Jimmy John's<br/>
                        1110 Concord Ave Ste. 20<br/>
                        Concord, CA 94520
                    </address>
                    <LocationMap lat="37.97902072204148" lng="-122.058067122176" zoom="14"/>
                    <div className="pb-3"><a className="btn btn-dark"
                                             href="//maps.apple.com/?sll=37.97902072204148,-122.058067122176&z=9&q=Jimmy%20John%27s%2C%201110%20Concord%20Ave%20Ste.%2020%2C%20Concord%2C%20CA%2094520&address=Jimmy%20John%27s%2C%201110%20Concord%20Ave%20Ste.%2020%2C%20Concord%2C%20CA%2094520"
                                             target="_blank" rel="noopener noreferrer"
                                             onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me to This
                        Store</a> <a className="btn btn-primary" href="tel:19252703482"
                                     onClick={this.handleCallStoreClick}>Call This Store</a>
                    </div>

                    <hr className="py-2"/>

                    <h2 className="text-primary">Jimmy John's &ndash; Concord #2</h2>
                    <OpenSign storeId="2" openingHours={this.openingHours}/>
                    <address>
                        Jimmy John's<br/>
                        785 Oak Grove Rd<br/>
                        Concord, CA 94518
                    </address>
                    <div className="pb-3"><a className="btn btn-dark"
                                             href="//maps.apple.com/?sll=37.93716297960219,-122.02513179808973&z=9&q=Jimmy%20John%27s%2C%20785%20Oak%20Grove%20Rd%2C%20Concord%2C%20CA%2094518&address=Jimmy%20John%27s%2C%20785%20Oak%20Grove%20Rd%2C%20Concord%2C%20CA%2094518"
                                             target="_blank" rel="noopener noreferrer"
                                             onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me to This
                        Store</a> <a className="btn btn-primary" href="tel:19252703681"
                                     onClick={this.handleCallStoreClick}>Call This Store</a>
                    </div>

                    <hr className="py-2"/>

                    <h2 className="text-primary">Jimmy John's &ndash; Walnut Creek</h2>
                    <OpenSign storeId="3" openingHours={this.openingHours}/>
                    <address>
                        Jimmy John's<br/>
                        2914 N Main St<br/>
                        Walnut Creek, CA 94597
                    </address>
                    <div className="pb-3"><a className="btn btn-dark"
                                             href="//maps.apple.com/?sll=37.9272920709125,-122.06121723887861&z=9&q=Jimmy%20John%27s%2C%202914%20N%20Main%20St%2C%20Walnut%20Creek%2C%20CA%2094597&address=Jimmy%20John%27s%2C%202914%20N%20Main%20St%2C%20Walnut%20Creek%2C%20CA%2094597"
                                             target="_blank" rel="noopener noreferrer"
                                             onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me to This
                        Store</a> <a className="btn btn-primary" href="tel:19259410014"
                                     onClick={this.handleCallStoreClick}>Call This Store</a>
                    </div>

                    <hr className="py-2"/>

                    <h2 className="text-primary">Jimmy John's &ndash; Brentwood</h2>
                    <OpenSign storeId="4" openingHours={this.openingHours}/>
                    <address>
                        Jimmy John's<br/>
                        5601 Lone Tree Way #130<br/>
                        Brentwood, CA 94513
                    </address>
                    <div className="pb-3"><a className="btn btn-dark"
                                             href="//maps.apple.com/?sll=37.96227620093857,-121.73105973455662&z=9&q=Jimmy%20John%27s%2C%205601%20Lone%20Tree%20Way%20%23130%2C%20Brentwood%2C%20CA%2094513&address=Jimmy%20John%27s%2C%205601%20Lone%20Tree%20Way%20%23130%2C%20Brentwood%2C%20CA%2094513"
                                             target="_blank" rel="noopener noreferrer"
                                             onClick={this.handleTakeMeToMyLocalStoreClick}>Take Me to This
                        Store</a> <a className="btn btn-primary" href="tel:19253084634"
                                     onClick={this.handleCallStoreClick}>Call This Store</a>
                    </div>

                    <hr className="pt-2"/>

                    <div className="text-center mt-5">
                        <Link className="btn btn-secondary" to="/">Back Home</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default LocationsPage
